import React from "react";
import ReactDOM from "react-dom";
import { GlobalStyle } from "./styles/GlobalStyle";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { getBackendUrl } from "./helpFunctions/general";

const history = createBrowserHistory();

if (process.env.REACT_APP_LOCAL_BACKEND) {
  console.log("Running in local backend mode");
}
// To test Sentry on local dev server, manually run `REACT_APP_SENTRY_DEV=true npm run start-dev`
if (process.env.NODE_ENV === "production" || process.env.REACT_APP_SENTRY_DEV === "true") {
  const sampleRate = parseFloat(process.env.REACT_APP_SENTRY_TRACING_SAMPLE_RATE || "1.0");
  const tracePropagationTargets = ["localhost", /^\//, getBackendUrl("")];
  // Set if running from output of npm run build
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],
    release: "fictive-front@" + process.env.REACT_APP_VERSION,
    environment: process.env.REACT_APP_PROJECT_ID,
    tracesSampleRate: sampleRate,
    sendDefaultPii: false,
    tracePropagationTargets,
  });
  console.log(`Initiated Sentry error tracing with sample rate ${sampleRate}`, tracePropagationTargets);
}
console.log(`Entered app with URL ${window.location.href}`);
console.log("Version " + (process.env.REACT_APP_VERSION || process.env.NODE_ENV));
console.log("Environment", process.env.REACT_APP_PROJECT_ID);
console.log("Database", process.env.REACT_APP_REALTIME_DB);

// TODO find why GlobalStyle needs to be run from here, not App/index.js, or the style gets a bit missed up
ReactDOM.render(
  <>
    <GlobalStyle />
    <App />
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
