import translations from "./translations.json";

export const defaultTranslations = translations["en-US"];

// Based on https://github.com/fictive-reality/schema/blob/v3/schemas/settings.json
// TODO make typesafe using generated code from schema repo
// TODO in the future may makes sense to store this setting on database root and read from there
// to allow different defaults in different environments, but it also adds one DB roundtrip
export const settings = {
  availableLocales: {
    "en-US": true,
    "en-GB": true,
    "sv-SE": true,
  },
  availableVoiceProviders: {
    azure: true,
    google: true,
    elevenlabs: false,
  },
  availableEnvironments: {
    office: true,
    apartment: true,
    gameshow: true,
    shop: true,
    cocaColaShop: true,
    classroom: true,
    coffeeShop: true,
    doctor: true,
    gym: true,
    street: true,
  },
  preferredUILocale: "en-US",
  availableAvatars: {
    "rpm:64eef8491db75f90dcfae642": {
      avatarUrl: "https://models.readyplayer.me/64eef8491db75f90dcfae642.glb",
      gender: "female",
      name: "Corporate Woman 1",
    },
    "rpm:64ef09d64a8548d9bc12ffe2": {
      avatarUrl: "https://models.readyplayer.me/64ef09d64a8548d9bc12ffe2.glb",
      gender: "female",
      name: "Corporate Woman 2",
    },
    "rpm:64ef0b3542c59d7dceb2f468": {
      avatarUrl: "https://models.readyplayer.me/64ef0b3542c59d7dceb2f468.glb",
      gender: "female",
      name: "Corporate Woman 3",
    },
    "rpm:64ef43ca4a8548d9bc134dfa": {
      avatarUrl: "https://models.readyplayer.me/64ef43ca4a8548d9bc134dfa.glb",
      gender: "male",
      name: "Customer Support Male 2",
    },
    "rpm:64ef44e61db75f90dcfb49bd": {
      avatarUrl: "https://models.readyplayer.me/64ef44e61db75f90dcfb49bd.glb",
      gender: "male",
      name: "Customer Support Male 1",
    },
    "rpm:64f091f84a8548d9bc1621cd": {
      avatarUrl: "https://models.readyplayer.me/64f091f84a8548d9bc1621cd.glb",
      gender: "male",
      name: "Doctor Male",
    },
    "rpm:64f0933042c59d7dceb61753": {
      avatarUrl: "https://models.readyplayer.me/64f0933042c59d7dceb61753.glb",
      gender: "male",
      name: "Customer Support Male 3",
    },
    "rpm:64fed836902030ca061e4e46": {
      avatarUrl: "https://models.readyplayer.me/64fed836902030ca061e4e46.glb",
      gender: "male",
      name: "Teenage Boy",
    },
    "rpm:64fef6f92297b4780139d8ae": {
      avatarUrl: "https://models.readyplayer.me/64fef6f92297b4780139d8ae.glb",
      gender: "female",
      name: "Teenage Girl",
    },
    "rpm:650039c41726485e4bf47ac8": {
      avatarUrl: "https://models.readyplayer.me/650039c41726485e4bf47ac8.glb",
      gender: "male",
      name: "Business Man",
    },
    "rpm:6500540eb9f61ba631e61af8": {
      avatarUrl: "https://models.readyplayer.me/6500540eb9f61ba631e61af8.glb",
      gender: "female",
      name: "Business Woman",
    },
    "rpm:650054f1b9f61ba631e61ce8": {
      avatarUrl: "https://models.readyplayer.me/650054f1b9f61ba631e61ce8.glb",
      gender: "male",
      name: "Casual Middleaged Man",
    },
    "rpm:65005723b9f61ba631e62205": {
      avatarUrl: "https://models.readyplayer.me/65005723b9f61ba631e62205.glb",
      gender: "female",
      name: "Casual Middleaged Woman",
    },
    "rpm:6500584b1726485e4bf4b51e": {
      avatarUrl: "https://models.readyplayer.me/6500584b1726485e4bf4b51e.glb",
      created: "2024-01-10T12:16:18.081Z",
      creator: "2VAM9swI0ud1nDlc7lYltnCCLoT2",
      gender: "male",
      name: "Older Man",
    },
    "rpm:650059c1034c10acbdf0824b": {
      avatarUrl: "https://models.readyplayer.me/650059c1034c10acbdf0824b.glb",
      gender: "female",
      name: "Older Woman",
    },
    "rpm:6523a41a7137f30e3a522e4a": {
      avatarUrl: "https://models.readyplayer.me/6523a41a7137f30e3a522e4a.glb",
      gender: "male",
      name: "PH ED Man",
    },
    "rpm:6523a4e87137f30e3a522f6a": {
      avatarUrl: "https://models.readyplayer.me/6523a4e87137f30e3a522f6a.glb",
      gender: "female",
      name: "PH ED Woman",
    },
    "rpm:65cf423b3fb278052b008335": {
      avatarUrl: "https://models.readyplayer.me/65cf423b3fb278052b008335.glb",
      gender: "female",
      name: "Girl",
    },
    "rpm:65c6362912681cab21399d94": {
      avatarUrl: "https://models.readyplayer.me/65c6362912681cab21399d94.glb",
      gender: "male",
      name: "Boy",
    },
  },
  availableRatings: {
    attitude: true,
    fillers: true,
    gaze: false,
    headMovements: true,
    hesitation: true,
    pitch: true,
    volume: true,
    wordRate: true,
    engagement: false,
    frustration: false,
    confusion: false,
    boredom: false,
    stressResilience: false,
    learnability: false,
    botLineIssues: false,
    gestures: false,
  },
  availableAgentProviders: {
    openai: true,
    static: true,
    none: true,
  },
  agentProvider: "openai",
  assessmentMode: false,
};

// Locales we can support (but may not be active for current context)
export const supportedLocales = {
  "en-US": { name: "English", greeting: "Hello, I'm your AI coach" },
  "en-GB": { name: "English (British)", greeting: "Hello, I'm your AI coach" },
  "sv-SE": { name: "Swedish", greeting: "Hej, jag är din AI coach" },
  "es-ES": { name: "Spanish", greeting: "Hola soy tu entrenadora de inteligencia artificial" },
  "de-DE": { name: "German", greeting: "Hallo, ich bin Ihr KI-Coach" },
  "fi-FI": { name: "Finnish", greeting: "Hei, olen tekoälyvalmentajasi" },
  "da-DK": { name: "Danish", greeting: "Hej, jeg er din AI-træner" },
  "nb-NO": { name: "Norwegian", greeting: "Hei, jeg er din AI-trener" },
  "fr-FR": { name: "French", greeting: "Bonjour, je suis votre coach IA" },
  "ar-SA": { name: "Arabic (Standard)", greeting: "مرحبًا، أنا مدرب الذكاء الاصطناعي الخاص بك" },
  "zh-CN": { name: "Chinese (Mandarin)", greeting: "你好, 我是你的AI教练" },
  "zh-HK": { name: "Chinese (Cantonese)", greeting: "大家好，我是你們的人工智能教練" },
  "hu-HU": { name: "Hungarian", greeting: "Hello, én vagyok az AI edzője" },
  "nl-NL": { name: "Dutch", greeting: "Hallo, ik ben je AI-coach" },
  "uk-UA": { name: "Ukrainian", greeting: "Привіт, я ваш тренер зі штучного інтелекту" },
  "ru-RU": { name: "Russian", greeting: "Здравствуйте, я ваш тренер по искусственному интеллекту" },
  "fa-IR": { name: "Persian", greeting: "سلام، من مربی هوش مصنوعی شما هستم" },
  "hi-IN": { name: "Hindi", greeting: "नमस्ते, मैं आपका एआई कोच हूं" },
  "id-ID": { name: "Indonesian", greeting: "Halo, saya adalah pelatih AI Anda" },
  "ur-PK": { name: "Urdu (Pakistan)", greeting: "سلام، میں اپ کا مصنوعی استاد ہوں۔" },
  "pa-IN": { name: "Punjabi", greeting: "ਹੈਲੋ, ਮੈਂ ਤੁਹਾਡਾ AI ਕੋਚ ਹਾਂ" },
  "pt-PT": { name: "Portuguese", greeting: "Olá, sou seu treinador de IA" },
  "pl-PL": { name: "Polish", greeting: "Cześć, jestem twoim trenerem AI" },
  "bn-IN": { name: "Bengali", greeting: "হ্যালো, আমি আপনার এআই কোচ" },
  "te-IN": { name: "Telugu", greeting: "హలో, నేను మీ AI కోచ్‌ని" },
};

export const supportedRatings = {
  attitude: true,
  fillers: true,
  gaze: false,
  headMovements: true,
  hesitation: true,
  pitch: true,
  volume: true,
  wordRate: true,
  engagement: false,
  frustration: false,
  confusion: false,
  boredom: false,
  stressResilience: false,
  learnability: false,
  botLineIssues: false,
  gestures: false,
};

export const isVideoEvaluation = evaluation => {
  return (
    evaluation === "gaze" ||
    evaluation === "engagement" ||
    evaluation === "frustration" ||
    evaluation === "confusion" ||
    evaluation === "boredom"
  );
};

/**
 * Call with availableLocales to limit, otherwise all supported will be made into options.
 * @param {*} availableLocales
 * @returns
 */
export const formatLocaleOptions = availableLocales => {
  // TODO should superAdmins automatically get all availableLocales ?
  return Object.entries(availableLocales || supportedLocales)
    .filter(([key, val]) => supportedLocales[key])
    .filter(([key, val]) => val !== false && val !== null && val !== undefined)
    .map(([key, val]) => ({
      id: key,
      name: supportedLocales[key].name,
      label: supportedLocales[key].name,
      value: key,
    }));
};

export const formatEnvironmentOptions = availableEnvironments => {
  // TODO should superAdmins automatically get all availableEnvironments ?
  return Object.entries(availableEnvironments || settings.availableEnvironments)
    .filter(([key, val]) => supportedEnvironments[key])
    .filter(([key, val]) => val === true)
    .map(([key, val]) => ({ name: supportedEnvironments[key].name, image: supportedEnvironments[key].image }));
};

export const getEnvironment = environmentName => {
  if (!environmentName) return null;
  return Object.values(supportedEnvironments).find(env => env.name === environmentName);
};

const office = require("../assets/environment_office.png");
const apartment = require("../assets/environment_apartment.png");
const shop = require("../assets/environment_shop.png");
const gameshow = require("../assets/environment_game_show.png");
const cocaColaShop = require("../assets/environment_cola.png");
const classroom = require("../assets/environment_classroom.png");
const coffeeShop = require("../assets/environment_coffeeshop.png");
const doctor = require("../assets/environment_doctor.png");
const gym = require("../assets/environment_schoolgym.png");
const street = require("../assets/environment_street.png");

export const supportedEnvironments = {
  office: { name: "Office", image: office },
  apartment: { name: "Apartment", image: apartment },
  gameshow: { name: "Game show", image: gameshow },
  shop: { name: "Shop", image: shop },
  cocaColaShop: { name: "CocaCola shop", image: cocaColaShop },
  classroom: { name: "Classroom", image: classroom },
  coffeeShop: { name: "Coffee shop", image: coffeeShop },
  doctor: { name: "Doctor's office", image: doctor, spawnPoints: ["Behind desk", "Front of desk", "Bed", "Bedside"] },
  gym: { name: "School Gym", image: gym },
  street: { name: "Street", image: street },
};

export const formatSpawnPointOptions = environment => {
  // TODO should superAdmins automatically get all availableVoiceProviders ?
  if (!environment) return null;
  let spawnPoints = Object.values(supportedEnvironments).find(env => env.name === environment).spawnPoints;
  if (spawnPoints?.length) {
    return spawnPoints.map(spawnPoint => ({ id: spawnPoint, name: spawnPoint }));
  } else {
    return null;
  }
};

/**
 * Formats which voices should be selectable, based on all downloaded (supported) voices, defined locale, gender and
 * which voice providers are configured to be available.
 * @param {Object} allVoices
 * @param {string[]} locale
 * @param {string} gender
 * @param {*} availableVoiceProviders
 * @param {boolean} showDeprecated
 * @returns
 */
export const formatVoiceOptions = (allVoices, locales, gender, availableVoiceProviders, showDeprecated) => {
  // TODO should superAdmins automatically get all availableVoiceProviders ?
  if (gender === "other") gender = null; // Count other as undefined, e.g. match all
  return Object.entries(allVoices || {})
    .filter(([voiceName, voice]) => {
      return !availableVoiceProviders || availableVoiceProviders?.[voice?.provider] === true;
    })
    .filter(([voiceName, voice]) => {
      return showDeprecated || voice?.deprecated !== true;
    })
    .filter(([voiceName, voice]) => {
      return (
        (!gender || voice?.genders?.includes(gender)) && (!locales || locales.every(l => voice?.locales?.includes(l)))
      );
    })
    .map(([voiceName, voice]) => ({ voiceName: voiceName, description: voice.description }));
};

// This is a hacky way to guess gender from a voice. If a voice has multiple genders, we just pick the first one.
export const voiceNameToGender = (allVoices, voiceName) => allVoices?.[voiceName]?.genders?.[0];

export const supportedVoiceProviders = {
  google: { id: "google", name: "Google", label: "Google", value: "google", suffix: "-G" },
  azure: { id: "azure", name: "Azure", label: "Azure", value: "azure", suffix: "-A" },
  elevenlabs: { id: "elevenlabs", name: "Eleven Labs", label: "Eleven Labs", value: "elevenlabs", suffix: "-E" },
};

export const formatAgentProviderOptions = availableAgentProviders => {
  // TODO should superAdmins automatically get all agent providers ?
  return Object.entries(availableAgentProviders || settings.availableAgentProviders)
    .filter(([key, val]) => supportedAgentProviders[key])
    .filter(([key, val]) => val === true)
    .map(([key, val]) => ({ id: key, name: supportedAgentProviders[key].name }));
};

export const supportedAgentProviders = {
  openai: { name: "LLM" },
  static: { name: "Scripted" },
  none: { name: "None" },
};

export const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" },
];

// Makes it easier to lookup which keys can be used for which settings
// Note that some available<X> settings can contain random IDs, e.g. voices, avatars,
// that need to be filled by checking the database
export const supportedKeys = {
  availableRatings: supportedRatings,
  availableLocales: supportedLocales,
  availableAgentProviders: supportedAgentProviders,
  availableVoiceProviders: supportedVoiceProviders,
  availableEnvironments: supportedEnvironments,
};
